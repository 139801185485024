function socialWindow(url) {
  var tamano = 700;
  var left = (window.screen.width - tamano) / 2;
  var top = (window.screen.height - tamano) / 2;
  var params = 'menubar=no,toolbar=no,status=no,width=' + tamano + ',height=' + tamano + ',top=' + top + ',left=' + left;
  window.open(url, 'NewWindow', params);
}

function setShareLinks() {
  var tweet; var
    url = '';
  var ogDesc = document.querySelectorAll("meta[property='og:description']");
  var pageUrl = encodeURIComponent(document.URL);
  var fb = document.querySelectorAll('.socialShare .facebook');
  var tw = document.querySelectorAll('.socialShare .twitter');
  var lin = document.querySelectorAll('.socialShare .linkedin');
  var wa = document.querySelectorAll('.socialShare .whatsapp');
  var tm = document.querySelectorAll('.socialShare .tumblr');
  var pr = document.querySelectorAll('.socialShare .print');
  var ml = document.querySelectorAll('.socialShare .mail');

  ogDesc.forEach(function (item) {
    tweet = typeof item.attributes.content === 'undefined' ? '' : item.attributes.content.textContent;
  });
  tweet = encodeURIComponent(tweet);
  // tweet = (tweet != '') ? '' : tweet;

  fb.forEach(function (item) {
    item.addEventListener('click', function () {
      url = 'https://www.facebook.com/sharer.php?u=' + pageUrl;
      socialWindow(url);
    });
  });

  tw.forEach(function (item) {
    item.addEventListener('click', function () {
      url = 'https://twitter.com/intent/tweet?url=' + pageUrl + '&text=' + tweet + '&via=' + item.dataset.via;
      socialWindow(url);
    });
  });

  lin.forEach(function (item) {
    item.addEventListener('click', function () {
      url = 'https://www.linkedin.com/shareArticle?mini=true&url=' + pageUrl;
      socialWindow(url);
    });
  });

  wa.forEach(function (item) {
    item.addEventListener('click', function () {
      const { number } = item.dataset;
      let { text } = item.dataset;
      text = text == '' ? pageUrl : text;
      url = 'https://wa.me/' + number + '?text=' + text;
      socialWindow(url);
    });
  });

  tm.forEach(function (item) {
    item.addEventListener('click', function () {
      url = 'http://www.tumblr.com/share/link?url=' + pageUrl;
      socialWindow(url);
    });
  });

  pr.forEach(function (item) {
    item.addEventListener('click', function () {
      window.print();
    });
  });

  ml.forEach(function (item) {
    item.addEventListener('click', function () {
      url = 'mailto:?body=' + pageUrl;
      socialWindow(url);
    });
  });
}

export default setShareLinks;
