/* eslint-disable no-new */
/* eslint-disable indent */
/**
 * IMPORTAR LOS JS QUE SEAN NECESARIOS PARA QUE FUNCIONE LA PLANTILLA
 * ESTO ES UN EJEMPLO, QUE NO TIENE POR QUE SER ASI:
 */
import Swiper, { Autoplay, Pagination, Navigation } from 'swiper/core';
import 'swiper/css/bundle';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'boxicons/css/boxicons.min.css';
import 'core-js';
import GLightbox from 'glightbox';
import AOS from 'Aos';
import 'aos/dist/aos.css';

/**
* Template Name: Day
* Updated: May 30 2023 with Bootstrap v5.3.0
* Template URL: https://bootstrapmade.com/day-multipurpose-html-template-for-free/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

(function () {
    AOS.init();
    /**
       * Easy selector helper function
       */
    const select = (el, all = false) => {
        el = el.trim();
        if (all) {
            return [...document.querySelectorAll(el)];
        }
        return document.querySelector(el);
    };

    /**
       * Easy event listener function
       */
    const on = (type, el, listener, all = false) => {
        const selectEl = select(el, all);
        if (selectEl) {
            if (all) {
                selectEl.forEach((e) => e.addEventListener(type, listener));
            } else {
                selectEl.addEventListener(type, listener);
            }
        }
    };

    /**
       * Easy on scroll event listener
       */
    const onscroll = (el, listener) => {
        el.addEventListener('scroll', listener);
    };

    /**
       * Navbar links active state on scroll
       */
    const navbarlinks = select('#navbar .scrollto', true);
    const navbarlinksActive = () => {
        const position = window.scrollY + 200;
        navbarlinks.forEach((navbarlink) => {
            if (!navbarlink.hash) return;
            const section = select(navbarlink.hash);
            if (!section) return;
            if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
                navbarlink.classList.add('active');
            } else {
                navbarlink.classList.remove('active');
            }
        });
    };
    window.addEventListener('load', navbarlinksActive);
    onscroll(document, navbarlinksActive);

    /**
       * Scrolls to an element with header offset
       */
    const scrollto = (el) => {
        const header = select('#header');
        let offset = header.offsetHeight;

        if (!header.classList.contains('header-scrolled')) {
            offset -= 16;
        }

        const elementPos = select(el).offsetTop;
        window.scrollTo({
            top: elementPos - offset,
            behavior: 'smooth',
        });
    };

    /**
       * Header fixed top on scroll
       */
    const selectHeader = select('#header');
    if (selectHeader) {
        const headerOffset = selectHeader.offsetTop;
        const nextElement = selectHeader.nextElementSibling;
        const headerFixed = () => {
            if ((headerOffset - window.scrollY) <= 0) {
                selectHeader.classList.add('fixed-top');
                nextElement.classList.add('scrolled-offset');
            } else {
                selectHeader.classList.remove('fixed-top');
                nextElement.classList.remove('scrolled-offset');
            }
        };
        window.addEventListener('load', headerFixed);
        onscroll(document, headerFixed);
    }

    /**
       * Back to top button
       */
    const backtotop = select('.back-to-top');
    if (backtotop) {
        const toggleBacktotop = () => {
            if (window.scrollY > 100) {
                backtotop.classList.add('active');
            } else {
                backtotop.classList.remove('active');
            }
        };
        window.addEventListener('load', toggleBacktotop);
        onscroll(document, toggleBacktotop);
    }

    /**
       * Mobile nav toggle
       */
    on('click', '.mobile-nav-toggle', function (e) {
        select('#navbar').classList.toggle('navbar-mobile');
        this.classList.toggle('bi-list');
        this.classList.toggle('bi-x');
    });

    /**
       * Mobile nav dropdowns activate
       */
    on('click', '.navbar .dropdown > a', function (e) {
        if (select('#navbar').classList.contains('navbar-mobile')) {
            e.preventDefault();
            this.nextElementSibling.classList.toggle('dropdown-active');
        }
    }, true);

    /**
       * Scrool with ofset on links with a class name .scrollto
       */
    on('click', '.scrollto', function (e) {
        if (select(this.hash)) {
            e.preventDefault();

            const navbar = select('#navbar');
            if (navbar.classList.contains('navbar-mobile')) {
                navbar.classList.remove('navbar-mobile');
                const navbarToggle = select('.mobile-nav-toggle');
                navbarToggle.classList.toggle('bi-list');
                navbarToggle.classList.toggle('bi-x');
            }
            scrollto(this.hash);
        }
    }, true);

    /**
       * Scroll with ofset on page load with hash links in the url
       */
    window.addEventListener('load', () => {
        if (window.location.hash) {
            if (select(window.location.hash)) {
                scrollto(window.location.hash);
            }
        }
    });

    /**
       * Preloader
       */
    const preloader = select('#preloader');
    if (preloader) {
        window.addEventListener('load', () => {
            preloader.remove();
        });
    }

    /**
       * Porfolio isotope and filter
       */
    window.addEventListener('load', () => {
        const portfolioContainer = select('.portfolio-container');
        if (portfolioContainer) {
            const portfolioIsotope = new Isotope(portfolioContainer, {
                itemSelector: '.portfolio-item',
            });

            const portfolioFilters = select('#portfolio-flters li', true);

            on('click', '#portfolio-flters li', function (e) {
                e.preventDefault();
                portfolioFilters.forEach(function (el) {
                    el.classList.remove('filter-active');
                });
                this.classList.add('filter-active');

                portfolioIsotope.arrange({
                    filter: this.getAttribute('data-filter'),
                });
                portfolioIsotope.on('arrangeComplete', function () {
                    AOS.refresh();
                });
            }, true);
        }
    });

    /**
       * Initiate portfolio lightbox
       */
    const portfolioLightbox = GLightbox({
        selector: '.portfolio-lightbox',
    });

    /**
       * Portfolio details slider
       */
    new Swiper('.portfolio-details-slider', {
        modules: [Pagination, Autoplay],
        speed: 400,
        loop: true,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });

    new Swiper('.sliderPrincipal', {
        modules: [Pagination, Autoplay, Navigation],
        speed: 400,
        loop: true,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });

    /**
       * Portfolio details slider
       */
    new Swiper('.slides-1', {
        modules: [Pagination, Autoplay],
        speed: 1000,
        loop: true,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },
    });

    /**
       * Animation on scroll
       */
    window.addEventListener('load', () => {
        AOS.init({
            duration: 1000,
            easing: 'ease-in-out',
            once: true,
            mirror: false,
        });
    });
}());
